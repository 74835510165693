@use '../../../assets/scss/global' as *;

.tradeCard {
    transition: all .2s ease;
    min-width: 17em;
    // max-width: 20em;
    margin: auto;
    box-shadow: 0 0 0 1px rgba(53, 72, 91, .14), 0 2px 2px rgba(0, 0, 0, .03), 0 4px 4px rgba(0, 0, 0, .04), 0 10px 8px rgba(0, 0, 0, .05), 0 15px 15px rgba(0, 0, 0, .06);
    &:hover {
        transition: all .2s ease;
        box-shadow: 0 0 0 1px rgba(53, 72, 91, .14), 0 2px 2px rgba(0, 0, 0, .03), 0 4px 4px rgba(0, 0, 0, .04), 0 10px 8px rgba(0, 0, 0, .05), 0 15px 15px rgba(0, 0, 0, .06), 0 30px 30px rgba(0, 0, 0, .07), 0 70px 65px rgba(0, 0, 0, .09);
    }
    
    @include breakpoints('xtra-small') {
        max-width: 22em;
    }
    @include breakpoints('small') {
        max-width: 19em;
    }

    &.card {
      border-color: #2d545e !important;  
    }
}
.tradeCard-body {
    padding-top: 0.5em;
    ul {
        padding: 0 .5em;
    }
    li {
        display: flex;
        text-align: left;
        color: #34435e;

        span:first-child {
            flex: 0 0 52%;
            color: #2d545e;
            font-weight: 600;
        }
        &:not(:first-child) {
            border-top: 1px dashed #34435e;
        }
    }
}
.tradeCard-boxShadow {
    box-shadow: 0 0 0 1px rgba(53, 72, 91, .14), 0 2px 2px rgba(0, 0, 0, .03), 0 4px 4px rgba(0, 0, 0, .04), 0 10px 8px rgba(0, 0, 0, .05), 0 15px 15px rgba(0, 0, 0, .06);
}