@use '../../../../assets/scss/global' as *;

.toolbar {
    button {
        border-radius: 7px;
        color: #2d545e;
        box-shadow: 1px 1px 1px #2d545e;
        background-color: #fff;
        border: 1px solid;

        &:hover {
            background-color: #e1b382;
        }
    }
}