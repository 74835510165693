@use '../../../assets/scss/global' as *;
.enter-trade {
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    position: fixed;
    display: flex;
    z-index: 1;
    background-color: rgba(5, 5, 5, 0.644);

    &::before {

    }
}
.enter-trade__layout {
    width: 800px;
    min-height: 900px;
    margin: auto;
    background-color: white;
    position: relative;
}
.enter-trade__close {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
}
.enter-trade__form-container {
    // width: 50%;
    max-width: 500px;
    margin: auto;
    padding: 5em;
}
.enter-trade__form-input-wrap {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    label {
        width: 100%;
        text-align: left;
    }
}
.enter-trade__form-container .input-group{
    flex-wrap: nowrap;

}
.enter-trade__form-container .input-group__time{
    margin-top: 1em;
}
.enter-trade__form-container .input-group .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.enter-trade__form {
 
}
.enter-trade__input-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
}
.enter-trade__save-btn {
    text-align: center;
}
.rs-picker-menu {
    z-index: 999999 !important;
}