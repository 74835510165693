@use '../../assets/scss/global' as *;

.dashboard__head {
    margin: 1em 0;
    color: #34435e;
    h1 {
        font-size: 1.5em;
        color: #403836;

        @include breakpoints('xtra-small') {
            font-size: 2em;
            margin: 1.1em 0;
        }
        @include breakpoints('small') {
            font-size: 3em;
        }
    }

    @include breakpoints('small') {
        
    }
}

.dashboard__overview-chart {
    max-width: 1200px;
    // padding: 1em .5em;
    margin: auto;
    // position: relative;
    // max-height: 300px;

    @include breakpoints('xtra-small') {
        // height: 400px;
    }
    @include breakpoints('small') {
        // padding: 1em 1.5em;
        // height: 500px;
    }
}