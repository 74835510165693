@use './assets/scss/global';

body {
  background-color: global.$main-background !important;
  font-family: 'JetBrains Mono', monospace !important; 
}
.App {
  text-align: center;
  // margin-bottom: 8em;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: rgba(99, 88, 88, 0.986) #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(99, 88, 88, 0.986);
    border-radius: 10px;
    border: 3px solid #ffffff;
  }