@use '../assets/scss/global' as *;

.navbar-logo-header {
    font-size: 1.2em;
    display: flex;
    align-items: center;

    @include breakpoints('xtra-small') {
        font-size: 1.3em;
    }
    @include breakpoints('small') {
        font-size: 1.4em;
    }

    img {
        &:hover {
            content: url("/assets/images/logo_black.png");
        }
    }
}
.navbar .container-fluid {
    justify-content: center;
}
.navbar #esponsive-navbar-nav {
    @include breakpoints('small') {
        flex: 0 0 auto;
    }
}