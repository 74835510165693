$breakpoints: (
    'xtra-small':  ( min-width:  481px ),
    'small':       ( min-width:  768px ),
    'medium':      ( min-width:  992px ),
    'large':       ( min-width: 1026px ),
    'xtra-large':       ( min-width: 1201px ),
    'xtra-xtra-large':       ( min-width: 1301px )
) !default;

@mixin breakpoints($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
        }
    }
};

$main-background: #f7f7f7;