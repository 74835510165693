@use '../../assets/scss/global.scss';

.Main__login {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    max-width: 500px;
    margin: 5em auto auto auto;
}
.login__header {
    h3 {
        margin-bottom: 1em;
    }
}
.login__form {
    width: 80%;
    border: 2px solid rgb(134, 127, 127);
    border-radius: 5px;
    -webkit-box-shadow: 16px -20px 5px 2px #e9d3d3;
    box-shadow: 16px -20px 5px 2px #e9d3d3;
    padding: 1em;
    .form-group {
        margin-bottom: 1em;
    }
}
.login__form-btn {
    text-transform: uppercase;
    margin-top: 1em;
}
.login__signup {
    margin-top: 1em;
}
.login__error {
    color: red;
    padding-bottom: 1em;
}
.login__divider-wrap {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: calc(100vw - 32px);
    max-width: 500px;
    margin: 20px 0px;
}
.login__divider-wrap::before, .login__divider-wrap::after {
    content: "";
    flex: 0 1 100%;
    border-bottom: 1px solid rgb(193, 199, 198);
    margin: 0px 10px;
    transform: translateY(-50%);
}
.login__divider-text {
    font-weight: bold;
    text-transform: uppercase;
}
.login__google-btn {
    width: 300px;
    min-height: 50px;
    border-radius: 5px;
    border: 1px solid grey;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .1s linear;
    
    &:hover {
        transition: all .1s linear;
        box-shadow: 3px 3px 1px 1px rgb(189, 183, 183);
        -webkit-box-shadow: 3px 3px 1px 1px rgb(189, 183, 183);
        transform: translateX(-2px) translateY(-2px)
        // -webkit-box-shadow: 2px 2px 2px 2px grey;
    }
    b{
        text-transform: uppercase;
        margin-left: 10px;
        margin-right: 1em;
    }
}