.c-logo__heading-img {
    width: 50px;
    height: 50px;
}
.c-auth__logo-heading {
    font-size: 2em;
    margin-bottom: 2em;

    h1 {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
    }
}