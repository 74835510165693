@import '../../assets/scss/variables';

.container-custom {
  background-color: #f8f9fa;
  padding: 2em;
  border-radius: 8px;
}

.tradeCard-boxShadow {
  border: 0px solid #007bff;
}

.card-header-custom {
  background-color: $table-header-color;
  color: white;
}

.card-title-custom {
  &.trade-details {
    color: #007bff;
  }
  &.statistics {
    color: #28a745;
  }
  &.executions {
    color: #dc3545;
  }
}

.list-group-item {
  strong {
    font-weight: bold;
  }
}

.pnl-positive {
  color: $profit-color;
}

.pnl-negative {
  color: $loss-color;
}

.table-executions {
  thead {
    background-color: #f8d7da;
  }
}
