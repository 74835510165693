@import '../../assets/scss/variables';

.calendar-header {
  background-color: rgb(34 35 35);
  color: white;
  text-align: center;
  font-weight: normal;
}

.calendar-body {
  background-color: #f8fafc;
  overflow-x: auto;
  border-radius: 10px;
}

.summary-header {
  background-color: #059669;
  color: white;
  text-align: center;
}

.summary-body {
  text-align: center;
  padding: 20px;
  .pnl {
      font-size: 1.5rem;
  }
}

.positive {
  color: $profit-color;
}

.negative {
  color: $loss-color;
}

.zero {
  color: grey;
}

.pnl {
  font-weight: bold;
  font-size: .95rem;
}

.calendar-cell {
  padding: 10px;
  text-align: center;
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
}

.calendar-date {
  font-size: 16px;
  font-weight: bold;
}

.calendar-cell:hover {
  background-color: #e5e7eb;
  cursor: pointer;
}

.calendar-cell.empty {
  background-color: #f0f0f0;
}

.tradeCard-boxShadow {
  border: 0px;
}

@media (max-width: 768px) {
  .calendar-header,
  .summary-header {
    text-align: center;
  }

  .calendar-body,
  .summary-body {
    text-align: center;
  }

  .summary-body {
    margin-top: 10px;
  }
}