@use '../assets/scss/global' as *;

.sideMenu-container {
    z-index: 99;
    position: fixed;
    bottom: 10px;
    width: 100vw;
    transition: all .5s ease;
}
.sideMenu-container--no-data {
    bottom: 25%;
    min-height: 70px;
    transition: all .5s ease;

    .sideMenu {
        height: 20vh;
        margin: 0 1em;
        min-height: 70px;
        width: auto;
        transition: all .5s ease;
        @include breakpoints('small') {
            width: 40vw;
            margin: auto;
            // left: calc(50vw - 20vw);
            transition: all .5s ease;
        }
    }
}
.sideMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    height: 70px;
    width: 200px;
    background-color: white;
    border: 1px solid #bababa;
    box-shadow: 0px 5px 0px 0px #bababa;
    border-radius: 10px;
    transition: all .5s ease;
}
.sideMenu__button {
    width: 50%;
    height:100%;
    color: black;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;

    &:not(:first-child) {
        border-left: 1px solid #bababa;
    }
}
.sideMenu__button--add {
    svg {
        color: rgb(84, 197, 84);
    }
}
.sideMenu__button--import {
    svg {
        color: rgb(160, 77, 160);
    }
}
.sideMenu__header--no-data {
    transition: all .5s ease;
    display: inline-block;
    border: 1px solid;
    background-color: #cdcdcd5c;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    padding: 0 1.5em;
    box-shadow: 0px -3px 0px 0px;

    h1 {
        font-size: 1.5em;
    }

    @include breakpoints('xtra-small') {
        h1 {
            font-size: 2em;
        }
    }
}